import { useHead } from '@unhead/vue';
import { onMounted, watch } from 'vue';
import { useI18n } from 'vue-i18n';

import { i18nLocaleStorage } from '@/config/i18n-config';
import { getFavIcons } from '@/utils/appearance-utils';

export function useI18nLocale() {
  const { locale } = useI18n();

  useHead({
    link: getFavIcons(),
    htmlAttrs: {
      lang: locale.value,
    },
  });

  onMounted(() => {
    watch(locale, (value) => {
      if (i18nLocaleStorage.fallbackLanguage === value) {
        i18nLocaleStorage.clear();
      } else {
        i18nLocaleStorage.set(locale.value);
      }
      document.documentElement.setAttribute('lang', value);
    });
  });
}
