import { captureException } from '@sentry/vue';
import axios, { type AxiosError, type AxiosResponse, type InternalAxiosRequestConfig, isAxiosError } from 'axios';
import memoize from 'memoize';
import type { Ref } from 'vue';

import { refreshToken } from '@/api/services/auth-service';
import { getHost } from '@/composables/get-host';
import { useSentry } from '@/composables/use-sentry';
import { defaultLanguage, i18n } from '@/config/i18n-config';
import router from '@/router';
import { navigationNames } from '@/router/router-constants';
import { useAuthStore } from '@/stores/auth-store';
import { getApiEndpoint } from '@/utils/env-utils';
import { isAxiosErrorResponse } from '@/utils/error-utils';

type IAuthRequestConfig<T> = InternalAxiosRequestConfig<T> & { sent?: boolean };

/**
 * A fully configured httpClient that redirects the user to the login if they request a secured endpoint that returns unauthorized.
 */
export const httpClient = axios.create({
  baseURL: getApiEndpoint(),
});

/**
 * A http client that does not force the user to login if a response returns unauthorized.
 * It just does not handle any errors.
 */
export const publicHttpClient = axios.create({
  baseURL: getApiEndpoint(),
});

const maxAge = 5000;
export const memoizedRefreshToken = memoize(refreshToken, {
  maxAge,
});

function requestHandler<T>(config: InternalAxiosRequestConfig<T>) {
  const { captureException } = useSentry();
  const authStore = useAuthStore();
  let locale = defaultLanguage;

  try {
    // Hack because types are wrong in i18n package
    locale = (i18n.global.locale as unknown as Ref<string>).value;
  } catch (e) {
    captureException(e);
  }

  // Append user language
  if (!config.headers?.['Content-Language']) {
    config.headers['Content-Language'] = locale;
  }

  // Append Origin. Used to determine the tenant
  config.headers['X-KIN-Origin'] = getHost() || '';

  // Append access token to each request if available
  if (authStore.accessToken) {
    config.headers['Authorization'] = `Bearer ${authStore.accessToken}`;
  }

  return config;
}

async function responseErrorHandler<T, D>(error: AxiosError<T, D>) {
  const authStore = useAuthStore();
  const config: IAuthRequestConfig<T> = error.config as IAuthRequestConfig<T>;

  const url: string = error.request.responseURL || '';

  // Skip error checks when the request was sent to the auth-api.
  // Requests against the auth API need to be treated differently.
  // All requests against the auth API are handled by a different axios instance though. But better be sage than sorry.
  if (url.endsWith('/oauth/token')) {
    throw error;
  }

  const refreshToken = authStore.refreshToken;

  // Check if we are dealing with a 401 and if we have not sent a refresh request yet.
  if (isAxiosError(error) && error.response?.status === 401 && !config?.sent && refreshToken) {
    // Mark the request config as sent to prevent infinite loops
    config.sent = true;

    // Set auth state to refreshing token to update the UI if necessary
    authStore.authState = 'refreshing-token';

    try {
      // Using memoized method here to prevent multiple refresh requests at once
      const response = await memoizedRefreshToken(refreshToken);
      // Successful refresh: Setting new auth data to store.
      authStore.setAuthResponse(response.data);

      // Retry the original request
      return httpClient(requestHandler(config));
    } catch (e) {
      // Refresh failed: Logging out because refresh token is invalid
      return await authStore.logout().then(() => {
        return router.push({ name: navigationNames.login });
      });
    }
  } else {
    // Any other error will be handled here
    if (isAxiosErrorResponse(error)) {
      if (error.response?.status === 401) {
        // User tries to access secured API. Logging out (if user is logged in)
        return await authStore.logout().then(() => {
          return router.push({ name: navigationNames.login });
        });
      } else {
        // Unhandled error. Logging to sentry.
        captureException(error);
      }
    }

    throw error;
  }
}

export async function responseSuccessHandler(response: AxiosResponse) {
  // Nothing to do yet on successful responses
  return response;
}

httpClient.interceptors.request.use(requestHandler);
httpClient.interceptors.response.use(responseSuccessHandler, responseErrorHandler);

publicHttpClient.interceptors.request.use(requestHandler);
publicHttpClient.interceptors.response.use(responseSuccessHandler);
