import { useQuery } from '@tanstack/vue-query';

import { getSetupData } from '@/api/services/initial-service';

export function useSetupDataQuery() {
  return useQuery({
    queryKey: ['setupData'],
    queryFn: () => getSetupData(),
    staleTime: Number.MAX_SAFE_INTEGER,
  });
}
