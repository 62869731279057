import { captureException as $sentryCaptureException } from '@sentry/vue';

export function useSentry() {
  return {
    captureException:
      $sentryCaptureException != null
        ? $sentryCaptureException
        : () => {
            // Ignore
          },
  };
}
