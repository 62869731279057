import { defineStore } from 'pinia';

type IConsentStorage = 'granted' | 'denied';

export interface IConsentSettings {
  functionality_storage: IConsentStorage;
  analytics_storage: IConsentStorage;
  ad_storage: IConsentStorage;
  ad_user_data: IConsentStorage;
  ad_personalization: IConsentStorage;
  personalization_storage: IConsentStorage;
  security_storage: IConsentStorage;
}

interface IEcommerceItem {
  item_name: string;
  item_id: string;
  unit_type: string;
  move_in_date: string;
  billing_period: string;
  insurance: string;
  products: string[];
}

export interface IEcommerceData {
  currency: string;
  value: number;
  items: IEcommerceItem[];
  payment_type?: string;
  transaction_id?: string;
  tax?: number;
  coupon?: string;
}

interface IDataLayerConsent {
  functionality_storage: IConsentStorage;
  analytics_storage: IConsentStorage;
  ad_storage: IConsentStorage;
  personalization_storage: IConsentStorage;
  security_storage: IConsentStorage;
}

interface IDataLayerEvent {
  event?: string;
  defaultConsent?: IDataLayerConsent;
  updateConsent?: IDataLayerConsent;
  pageUrl?: string;
  pageTitle?: string;
  ecommerce?: IEcommerceData | null;
}

export const useTrackingStore = defineStore('tracking', () => {
  // Ensure dataLayer is initialized
  if (!window.dataLayer) {
    window.dataLayer = [];
  }

  function pushToDataLayer(event: IDataLayerEvent) {
    window.dataLayer.push(event);
  }

  function updateConsent(settings: IConsentSettings) {
    gtag('consent', 'update', settings);
  }

  function pushVirtualPageview(pageTitle: string) {
    pushToDataLayer({
      event: 'virtualPageview',
      pageUrl: window.location.href,
      pageTitle,
    });
  }

  function pushAddToCart(data: IEcommerceData) {
    pushToDataLayer({
      ecommerce: null,
    });
    pushToDataLayer({
      event: 'add_to_cart',
      ecommerce: data,
    });
  }

  function pushBeginCheckout(data: IEcommerceData) {
    pushToDataLayer({
      event: 'begin_checkout',
      ecommerce: data,
    });
  }

  function pushAddShippingInfo(data: IEcommerceData) {
    pushToDataLayer({
      event: 'add_shipping_info',
      ecommerce: data,
    });
  }

  function pushAddPaymentInfo(data: IEcommerceData) {
    pushToDataLayer({
      event: 'add_payment_info',
      ecommerce: data,
    });
  }

  function pushPurchase(data: IEcommerceData) {
    pushToDataLayer({
      event: 'purchase',
      ecommerce: data,
    });
  }

  function resetEcommerceData() {
    // First clear any existing ecommerce data
    pushToDataLayer({
      ecommerce: null,
    });
  }

  return {
    // Actions
    updateConsent,
    pushVirtualPageview,
    pushAddToCart,
    pushBeginCheckout,
    pushAddShippingInfo,
    pushAddPaymentInfo,
    pushPurchase,
    resetEcommerceData,
  };
});
