<template>
  <div class="relative">
    <input
      :id="elementId"
      class="peer absolute left-[-10000px] size-[0.01px] overflow-hidden opacity-0"
      :class="{ invalid: invalid }"
      :disabled="disabled"
      :name="name"
      type="checkbox"
      :value="modelValue"
      :aria-invalid="invalid"
      @change="emit('update:modelValue', !modelValue)"
    />
    <label
      :for="elementId"
      class="flex items-center gap-4 peer-enabled:cursor-pointer peer-disabled:cursor-not-allowed peer-disabled:fill-darkDisabled peer-disabled:opacity-50"
    >
      <span
        class="checkmark-area relative inline-flex items-center rounded-[2px]"
        :class="{ 'text-base': size === 'sm' }"
        data-test-id="checkbox-input"
      >
        <span class="block">
          <MaterialSymbolsCheckBoxOutlineBlank
            v-if="!modelValue"
            :class="checkmarkClasses"
          />
          <MaterialSymbolsCheckBoxRounded
            v-else
            :class="checkmarkClasses"
          />
        </span>
      </span>

      <span
        v-if="$slots.default"
        class="block w-full"
        :class="{ 'text-danger': invalid }"
        data-test-id="checkbox-label"
      >
        <slot />
      </span>
    </label>
  </div>
</template>

<script lang="ts" setup>
import { uniqueId } from 'lodash-es';
import { computed } from 'vue';

interface Props {
  id?: string;
  name?: string;
  disabled?: boolean;
  invalid?: boolean;
  modelValue?: boolean;
  size?: 'md' | 'sm';
}

const props = withDefaults(defineProps<Props>(), {
  id: undefined,
  name: undefined,
  disabled: false,
  invalid: false,
  modelValue: false,
  size: 'md',
});

const emit = defineEmits<{ (e: 'update:modelValue', data: boolean): void }>();

const fallbackId = uniqueId('checkbox');

const elementId = computed(() => props.id || fallbackId);
const checkmarkClasses = computed(() => {
  return (props.invalid ? 'text-danger' : props.disabled ? 'text-darkDisabled' : 'text-primary') + ' scale-125';
});
</script>

<style scoped lang="scss">
label > .checkmark-area {
  input:valid:focus-visible + & {
    @apply outline outline-2 outline-offset-2 outline-text/50;
  }

  input:invalid:focus-visible + &,
  input.invalid:focus-visible + & {
    @apply text-danger outline outline-2 outline-offset-2 outline-danger/50;
  }
}
</style>
