import { useQuery } from '@tanstack/vue-query';
import type { MaybeRefOrGetter } from 'vue';
import { computed, toRef } from 'vue';

import { getTranslations } from '@/api/services/translation-service';

export function useTranslationQuery(language: MaybeRefOrGetter<string | undefined | null>) {
  const langRef = toRef(language);

  return useQuery({
    queryKey: ['language', langRef],
    queryFn: () => getTranslations(langRef.value as string),
    enabled: computed(() => !!langRef.value),
    staleTime: Number.MAX_SAFE_INTEGER,
  });
}
