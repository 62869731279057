<template>
  <footer
    v-if="showFooter"
    class="bottom-0 flex w-full flex-col items-center px-4"
  >
    <hr class="w-full" />
    <div class="flex flex-wrap items-center gap-2 pb-2 pt-3">
      <UIExternalLink
        v-if="imprintUrl"
        :to="imprintUrl"
      >
        {{ $t('footer.imprint') }}
      </UIExternalLink>
      <span v-if="imprintUrl && privacyPolicyUrl">|</span>
      <UIExternalLink
        v-if="privacyPolicyUrl"
        :to="privacyPolicyUrl"
      >
        {{ $t('footer.privacyPolicy') }}
      </UIExternalLink>
    </div>
  </footer>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { useRoute } from 'vue-router';

import { useCurrentLocation } from '@/composables/use-current-location';
import UIExternalLink from '@/modules/ui/components/UIExternalLink.vue';
import { useAppStore } from '@/stores/app-store';

const route = useRoute();

const appStore = useAppStore();
const { location } = useCurrentLocation();

// TODO: Refactor this because this is not a good way to check if we are in the customer portal & could break in the future
const isCustomerPortalRoute = computed(() => route.fullPath?.includes('customer-portal'));

const showFooter = computed(() => {
  if (isCustomerPortalRoute.value) {
    return !!appStore.imprintUrl || !!appStore.privacyPolicyUrl;
  }
  return (
    !!location.value?.imprintUrl ||
    !!location.value?.privacyPolicyUrl ||
    !!appStore.imprintUrl ||
    !!appStore.privacyPolicyUrl
  );
});

const imprintUrl = computed(() =>
  isCustomerPortalRoute.value ? appStore.imprintUrl : location.value?.imprintUrl || appStore.imprintUrl,
);
const privacyPolicyUrl = computed(() =>
  isCustomerPortalRoute.value
    ? appStore.privacyPolicyUrl
    : location.value?.privacyPolicyUrl || appStore.privacyPolicyUrl,
);
</script>

<style scoped lang="scss">
a {
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}
</style>
